import { CCarousel, CCarouselCaption, CCarouselItem, CImage  } from '@coreui/react'
import Lang from "lang.js";

var lang = new Lang();
var applicationMessages = await fetch("/lang.dist.js")
    .then((response) => response.json())
    .then((json) => {
        return json;
    });
lang.setMessages(applicationMessages);

export default function LoginCarousel() {
    return (
        <CCarousel indicators transition="crossfade">
            <CCarouselItem className="login_slider_item">
                <CImage className="login_slider_item_image" src="/marketing-automation.png"/>
                <CCarouselCaption className="login_slider_item_caption">
                    <div>
                        <h3>{lang.get("rido.login_screen.part_one")}</h3>
                        <p>{lang.get("rido.login_screen.part_one_description")}</p>
                    </div>
                </CCarouselCaption>
            </CCarouselItem>
            <CCarouselItem className="login_slider_item">
                <CImage className="login_slider_item_image" src="/lead-management.png"/>
                <CCarouselCaption className="login_slider_item_caption">
                    <div>
                        <h3>{lang.get("rido.login_screen.part_two")}</h3>
                        <p>{lang.get("rido.login_screen.part_two_description")}</p>
                    </div>
                </CCarouselCaption>
            </CCarouselItem>
        </CCarousel>
    );
}
